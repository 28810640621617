@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "../../../variables";

.invalid-funding-code {
  padding-left: 0;
  padding-right: 0;
  margin-top: -2rem;
  font-family: "PayPalSansSmall-Light", Helvetica Neue, Arial, sans-serif !important;
  font-size: small;

  &__button {
    @media (max-width: $ppui-grid-media-query-sm) {
      width: 15rem;
      margin-bottom: 1rem;
    }
  }
}
