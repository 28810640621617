@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import "../../../../mixins";
@import "../../../../variables";

.nav-links {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  display: none;
  align-items: center;
  text-transform: uppercase;
  @media (min-width: $ppui-grid-media-query-lg) {
    display: flex;
  }

  li > ul,
  li ul {
    padding: 0.625rem 1.25rem 1.25rem 1.25rem;
    &a::before {
      text-decoration: none;
    }
  }
}
