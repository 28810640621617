@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "../../../variables";

.where-is-this-label {
  width: fit-content;
  display: inline-grid;

  @media (max-width: $ppui-grid-media-query-sm) {
    padding: 0;
  }

  .underline-text {
    text-decoration: underline;
    cursor: pointer;
    display: block;
    margin-bottom: 0;
    z-index: 100;
  }

  .explanation-box {
    margin-top: 0.65rem;
    max-width: fit-content;
    background-color: $pal-blue;
    z-index: 4;
    width: fit-content;
    height: auto;
    padding: 1.125rem 1.125rem 3rem;
    transition: 0.2s ease-in-out;
    position: absolute;
    margin-top: 3rem;

    p {
      line-height: normal;
      text-align: left;
      width: 18.75rem;
      margin: 0;

      &.funding-code-sample-label {
        text-align: center;
        font-weight: bold;
      }
    }

    input {
      text-align: center;
      border-radius: 1.5rem;
      margin-top: 0.5rem;
    }

    &::after {
      top: -0.65rem;
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-bottom: 0.75rem solid #009fda;
      content: "";
      height: 0;
      left: 2rem;
      position: absolute;
      width: 0;
    }
  }
}
