@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import "../../../mixins";

.hero {
  @include cover-background;

  background-color: $ppui-color-black;
  color: $ppui-color-white;
  height: auto;
  position: relative;
  margin-top: 3.4375rem;

  @media (min-width: $ppui-grid-media-query-lg) {
    margin-top: 0;
  }

  a {
    color: $ppui-color-white;
    &:hover {
      text-decoration: none;
    }
  }

  .tickler {
    text-align: center;
    margin: auto;
    padding: 1.875rem 0;

    @media (min-width: $ppui-grid-media-query-lg) {
      bottom: 1.25rem;
      position: absolute;
      right: 1.25rem;
    }
  }

  .no-margins {
    display: none;
    margin: 0;

    @media (min-width: $ppui-grid-media-query-lg) {
      display: block;
    }
  }
}
