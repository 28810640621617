@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.eligible-expenses {
  &__container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__header {
    text-align: center;
  }

  &__subheader {
    text-align: center;
  }

  &__list {
    padding-left: 1.25rem;

    .condense-top-margin {
      margin-top: -1.25rem;
    }
  }

  .link {
    display: block !important;
    text-align: center;
  }
}

.gray {
  background-color: $ppui-color-grey-100;
}
