@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.ppp-loan-info-background {
  background-image: url("./hero_small_business.jpg");
  background-position: center;
  background-size: cover;
}

.ppp-loan-info-hero {
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 20rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: $ppui-grid-media-query-lg) {
    display: block;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }

  &__btn {
    margin-left: 1.2rem;
    width: 20rem;

    @media (max-width: $ppui-grid-media-query-sm) {
      display: none !important;
    }
  }

  &__title {
    margin-bottom: 0;

    @media (min-width: $ppui-grid-media-query-md) {
      margin-left: 2rem;
      margin-right: 2rem;
      text-align: left;
    }

    @media (max-width: $ppui-grid-media-query-sm) {
      text-align: center;
    }
  }

  &__sub-title {
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: $ppui-grid-media-query-md) {
      margin-left: 2rem;
      margin-right: 2rem;
      text-align: left;
    }

    @media (max-width: $ppui-grid-media-query-sm) {
      text-align: center;
    }
  }

  &__text-block {
    margin-top: 1rem;
    width: 60%;

    @media (min-width: $ppui-grid-media-query-md) {
      margin-left: 2rem;
      margin-right: 2rem;
      text-align: left;
    }

    @media (max-width: $ppui-grid-media-query-sm) {
      display: none;
    }
  }

  &__get-started {
    margin-top: 1rem;
    width: 20rem;

    @media (min-width: $ppui-grid-media-query-md) {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    @media (max-width: $ppui-grid-media-query-sm) {
      display: none !important;
    }
  }
}
