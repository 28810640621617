@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.ppp-2-loan-basics{
  padding: 2rem 0 2rem;
  background-color: $ppui-color-grey-100;

  .loan-basics-header-and-link {
    align-items: center;
    justify-content: center;
    text-align: center;
    .ppvx_text--xl3 {
      margin-bottom: 1rem;
    }
  }

  .disclaimer{
    color: $ppui-color-grey-600;
    text-align: center;
  }

  .loan-basics{
    justify-self: center;
    margin: 2rem 0 0;
  }
}
