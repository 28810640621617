@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.ppp-row-bullet {
  margin-bottom: 3rem;
  padding: 0.6rem;

  &.left-align {
    text-align: left;
  }

  .ppvx_row {
    align-items: center !important;
    &.left-align {
      justify-content: left !important;
    }
  }

  .icon {
    height: 4.1rem;
    width: 4.1rem;
    margin: 0 auto;

    @media (max-width: $ppui-grid-media-query-md) {
      margin-bottom: 0;
      height: 3rem;
      width: 3rem;
    }

    img {
      @extend .icon;
    }
  }

  @media (min-width: $ppui-grid-media-query-lg) {
    margin-bottom: 0;
  }

  .grey-text {
    margin: 0 auto;
    color: $ppui-color-grey-600;
  }
  .white-text {
    margin: 0 auto;
    color: $ppui-color-white;
  }
}
