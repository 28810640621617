@mixin cover-background () {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

@mixin anchor-animation () {
  background-color: #1baff8;
  bottom: 0;
  content: "";
  height: 0.125rem;
  left: 0;
  right: 0;
  top: 1.25rem;
  margin: 0 auto;
  opacity: 0;
  position: absolute;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  width: 0;
}
