@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.small-business-funding {
  background: $ppui-color-grey-100;

  .small-business-funding-bullets {
    padding-top: 2rem;
  }

  .small-business-funding-disclaimers {
    text-align: left;

    &__sup {
      margin-top: 0.5rem;
    }
  }
}
