@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.hero-home-content {
  text-align: center;

  @media (min-width: $ppui-grid-media-query-lg) {
    padding-top: 3.125rem;
    text-align: left;
  }
}

.hero-home-background {
  background-image: url("../../../shared/images/home-hero.jpg");
  background-position: center;

  .check-eligibility-button {
    margin-block-end: 1em;
    max-width: 21rem;
    padding: 0.8125rem 1.875rem;
    width: 100%;
  }

  .column-bullet {
    justify-content: center;

    @media (min-width: $ppui-grid-media-query-lg) {
      justify-content: inherit;
      padding-left: 1.25rem;
    }

    .content {
      max-width: 6.25rem;
    }
  }

  .disclosure {
    align-items: flex-end;
    flex-grow: 1;
    margin-top: 2.75rem;
  }
}
