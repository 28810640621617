@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import "../../../../variables";

.call-us-today {
  background-color: $ppui-color-blue-600;
  color: $ppui-color-white;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: index($layers, call-us-today);
  height: 0;
  transition: 0.2s ease-in-out;
  transition-property: height;

  &.visible {
    height: 4.2em;
  }

  @media (min-width: $ppui-grid-media-query-lg) {
    display: block;
  }

  .inner-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .phone-link {
      text-decoration: none !important;
    }

    div {
      padding: 0.625rem 2.5rem;
      flex-direction: row;

      &.has-divider {
        border-right: 0.0625rem solid $ppui-color-white;
      }

      &.has-button {
        max-width: 15.625rem;

        .check-eligibility-button {
          max-width: 100%;
        }
      }
    }
  }
}
