@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import "~@swift-paypal/pp-vx/scss/components/text/text.scss";
@import "../../../../variables";

.faqs {
  &.content-block {
    padding: 5rem 1.25rem 3.75rem 1.25rem;

    &.no-content {
      padding: 2.5rem 1.25rem 3.75rem 1.25rem;
    }

    .see-more {
      text-align: left;
      margin-left: 1.5rem;
      margin-top: 1rem;
      a {
        color: $ppui-color-blue-400;
        font-size: 1rem;
        font-weight: normal;

        .dropdown__arrow {
          height: 0.75rem;
          margin-left: 0.25rem;
        }
      }

      @media (max-width: $ppui-grid-media-query-xl) {
        margin-bottom: 2.5rem
      }
    }

    .contact {
      border: $ppui-border-width-xs solid $tertiary-bg;
      height: fit-content;

      @media (min-width: $ppui-grid-media-query-xl) {
        float: right;
        width: 23rem;
      }

      .contact-info {
        text-align: left;
        line-height: 1.85rem;
        margin: 1.25rem 0 1.25rem 0;

        div {
          color: white;
          display: inline-block;
        }

        .contact-detail {
          width: calc(100% - (5rem + 10px));
          vertical-align: top;

          &.send-us-email {
            margin-top: 2rem;
          }
        }

        .icon--small {
          display: inline-block;
          width: 5rem;
          height: 2.5rem;
          margin: 20px 0 20px 10px;
          background-position: center;
        }
      }

      .contact-link {
        color: white;
        text-decoration: underline;
        font-weight: lighter;
      }
    }
  }

    .title {
      padding-bottom: 7.5rem;
      font-size: 2.25rem;
    }

    .accordian-override {
      .ppvx_accordion {
        border-collapse: collapse;
        padding-bottom: 3rem;
      }

      .ppvx_accordion__row {
        border-bottom: none;
        display:flex;
        flex-direction: column;
        justify-content: flex-end;

        &.no-contact-row {
          margin-left: auto;
          margin-right: auto;
        }
      }

      .ppvx_accordion__body {
        display: none;
        padding: 0.5rem 1.25rem 0;
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: "PayPalSansSmall-Regular", Helvetica Neue, Arial, sans-serif;
        font-weight: normal;

        p {
          margin: 0 0 1.2rem 0;
          text-align: left;
        }

        ul {
          padding-left: 0;
          text-align: left;

          li {
            list-style-type: none;
          }
        }
      }

      .ppvx_accordion__header {
        cursor: pointer;
        padding: 1.25rem;
        transition: 0.2s ease-in-out;
        position: relative;
        border: .125rem solid #333333;
        width: 100%;
        text-align: left;
        font-size: 1rem;
        line-height: 1.3rem;
        font-family: "PayPalSansBig-Regular", sans-serif;
        font-weight: 100;
        word-break: break-word;
        min-height: 2.625rem;

        &::before,
        &::after {
          border: none;
          content: "";
          position: absolute;
          top: 20px;
          bottom: 0px;
          width: 3px;
          height: 12px;
          transition: 0.2s ease-in-out;
        }

        &::before {
          transform: rotate(45deg);
          right: 10px;
        }

        &::after {
          transform: rotate(-45deg);
          right: 16.5px;
        }
      }

      .ppvx_accordion__header:focus {
        border: .125rem solid #0070ba;
        margin-bottom: 0;
        &:after {
          box-shadow: none;
          left: inherit;
        }
      }

      .ppvx_accordion__header[aria-expanded='true'] {
        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }

      .ppvx_accordion__header[aria-expanded='true'] + .ppvx_accordion__body {
        display: block;
      }
    }

    .qa {
      margin-bottom {
        margin: 0;
      }
    }

  &.gray {
    background-color: #333;

    .title {
      color: $ppui-color-white;
    }

    .accordian-override {
      .ppvx_accordion__body {
        color: $ppui-color-white;
      }

      .ppvx_accordion__header {
        color: $ppui-color-white;
        border-top: $ppui-border-width-xs solid $tertiary-bg;
        margin-bottom: $ppui-border-width-xs;

        &::before,
        &::after {
          background-color: $tertiary-bg;
        }

        &:hover {
          color: $tertiary-bg;
        }
        &:focus {
          border: .125rem solid #0070ba;
          margin-bottom: 0;
          color: $tertiary-bg;
        }
      }

      .ppvx_accordion__header[aria-expanded='true'] {
        color: $tertiary-bg;
      }

      &.no-contact-row {
        max-width: 40rem;
      }
    }

    .ppvx_accordion__row:last-of-type {
      .ppvx_accordion__header {
        border-bottom: $ppui-border-width-xs solid $tertiary-bg;
      }
    }

    .ppvx_accordion__row:focus-within + .ppvx_accordion__row {
      .ppvx_accordion__header {
        border-top: none;
      }
    }
  }

  .ppvx_accordion__row:first-of-type {
    border-top: none;

    .ppvx_accordion__header {
      border-top: none;
    }
  }

  &.white {
    .see-more {
      text-align: left;
      margin-left: 1.5rem;

      a {
        color: $ppui-color-blue-400;
        font-size: 1rem;
        font-weight: normal;

        .dropdown__arrow {
          height: 0.75rem;
          margin-left: 0.25rem;
        }
      }

      @media (max-width: $ppui-grid-media-query-xl) {
        margin-bottom: 2.5rem
      }
    }

    .accordian-override {
      .ppvx_accordion__body {
        display: none;
        padding: 0.5rem 1.25rem 0;
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: "PayPalSansBig-Regular", Helvetica Neue, Arial, sans-serif;

        p {
          margin: 0 0 1.2rem 0;
          text-align: left;
        }

        ul {
          padding-left: 0;
          text-align: left;

          li {
            list-style-type: none;
          }
        }
      }

      .ppvx_accordion__header {
        cursor: pointer;
        padding: 1.5rem 2.5rem 0.25rem 1.25rem;
        transition: 0.2s ease-in-out;
        position: relative;
        border: none;
        border-top: 0.125rem solid $ppui-color-grey-200;
        width: 100%;
        text-align: left;
        font-size: 1.3rem;
        line-height: 2rem;
        font-family: "PayPalSansBig-Regular", sans-serif;
        font-weight: 200;
        word-break: break-word;

        &::before,
        &::after {
          content: "";
          position: absolute;
          margin-top: 2rem;
          top: 0;
          bottom: 1rem;
          width: 0.1rem;
          height: 1rem;
          background-color: $ppui-color-grey-300;
          transition: 0.2s ease-in-out;
        }

        &::before {
          transform: rotate(45deg);
          right: 0.5rem;
        }

        &::after {
          transform: rotate(-45deg);
          right: 1.2rem;
        }
      }

      .ppvx_accordion__header:focus {
        outline: none;
        &:after {
          box-shadow: none;
          border: none;
          left: inherit;
        }
      }

      .ppvx_accordion__header[aria-expanded='true'] {
        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }

      .ppvx_accordion__header[aria-expanded='true'] + .ppvx_accordion__body {
        display: block;
      }
    }

    .qa {
      margin-bottom {
        margin: 0;
      }
    }
  }
}
