@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.must-be-used-container {
  max-width: $ppui-grid-media-query-lg;
  padding-bottom: 3rem;

  .must-be-used-header{
    text-align: center;
  }

  .must-be-used-icon {
    display: flex;
    height: 5rem;
    width: 5rem;
    margin: 2rem auto;

    @media (max-width: $ppui-grid-media-query-md - 0.0625rem) {
      display: none;
    }

    img {
      @extend .must-be-used-icon;
    }
  }

  .step-image {
    color: $ppui-color-grey-700;
    background-color: initial;
    border: none;
    border-radius: 0;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 1rem;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: block;
      align-items: start;
    }

    .number {
      color: $ppui-color-grey-700;
      flex: 0 auto;
      height: 3rem;
      width: 3rem;
      border: 1px solid $ppui-color-grey-300;
      border-radius: 100%;
      line-height: 2.7rem;
      text-align: center;
      margin: auto 0 auto auto;
    }
  }

  .disclaimer-text {
    text-align: center;
    display: block;

    a {
      @extend .disclaimer-text;
    }
  }
}
