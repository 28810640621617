@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.request-forgiveness {
  background-color: $ppui-color-grey-100;


  &__button {
    display: block !important; // Have to do this because of `no-line-animation`
    margin-left: auto;
    margin-right: auto;
    width: 20rem;
  }

  &__container {
    padding-top: 1rem;
    padding-bottom: 4rem;
  }

  &__header {
    text-align: center;
  }

  &__sba-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
