@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.sitelink-faq-hero {
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 24rem;

  &__button {
    margin-top: 1rem;

    @media (min-width: $ppui-grid-media-query-sm) {
      width: 20rem;
    }
  }

  &__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
