@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.hero-ppbl-more-info-content {
  height: 24rem;

  .header-text {
    @media (max-width: $ppui-grid-media-query-lg) {
      text-align: center;
      font-size: 1.875rem;
      line-height: 2.25rem;
      margin-top: 3.5rem;
    }
  }

  .sub-header-text {
    @media (max-width: $ppui-grid-media-query-lg) {
      display: none;
    }
  }

  .button-section {
    text-align: center;

    .ppvx_btn {
      width: 100%;
    }

    @media (max-width: $ppui-grid-media-query-lg) {
      display: none;
    }
  }

  .sign-up-link {
    color: $ppui-color-white;
  }
}

.hero-ppbl-more-info-background {
  background-image: url("./ppbl-more-info-hero_image.jpg");
  background-position: 50%;
}
