@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.ppbl-faq-hero {
  height: 24rem;
  align-content: center;

  @media (max-width: $ppui-grid-media-query-lg) {
    text-align: center;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-top: 3.5rem;
  }

  .ppvx_text--xl4 {
    margin-top: 3rem;
  }

  .sub-header-text {
    @media (max-width: $ppui-grid-media-query-lg) {
      display: none;
    }
  }

  &__button {
    @media (max-width: $ppui-grid-media-query-lg) {
      display: none;
    }

    @media (min-width: $ppui-grid-media-query-sm) {
      text-align: center;
      margin: 2rem 0 0;
    }
  }
}
