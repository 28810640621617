@import "../../../variables";

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(234%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.mailer {
  animation: fadein 3s;
  margin: auto;
  max-width: 11.25rem;
  position: relative;
  text-align: center;
  z-index: index($layers, mailer);
}
