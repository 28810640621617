@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.apply-for-ppp{
  padding: 2rem 0 2rem;
  background-color: $ppui-color-grey-100;

  .apply-for-ppp-title {
    text-align: center;
    color: $ppui-color-grey-700;
  }
  .apply-now-cta {
    text-align: center;
  }
}
