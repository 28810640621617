@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.header.ppbl {
  background: linear-gradient(rgba($ppui-color-black, 0.4), $ppui-color-transparent);
  position: initial;

  .logos {
    display: flex;

    .logo {
      max-width: 8.5rem;
    }
  }

  .log-in {
    .ppvx_btn--inverse {
      background-color: $ppui-color-white;
      border-color: $ppui-color-white;
      color: $ppui-color-grey-700;
  
      &:visited {
        color: $ppui-color-grey-700;
      }
  
      &:hover,
      &:focus,
      &:active {
        background-color: $ppui-color-grey-200;
        text-decoration: underline;

        &::after {
          border: none;
          box-shadow: none;
        }
      }
    }

    .ppvx_btn--secondary {
      &.ppvx_btn--inverse {
        background: $ppui-color-transparent;
        border-color: $ppui-color-white;
        color: $ppui-color-white;
    
        &:visited {
          color: $ppui-color-white;
        }
    
        &:hover,
        &:focus,
        &:active {
          background: $ppui-color-transparent;
          box-shadow: none;
          color: $ppui-color-white;
          text-decoration: underline;
        }
      }
    }
  }

  .desktop-content {
    @media (max-width: $ppui-grid-media-query-lg) {
      display: none;
    }
  }

  .mobile-content {
    display: none;

    .mobile-content-column {
      display: flex;
      justify-content: center;
      height: 4rem;
    }

    .logo {
      width: 1.625rem;
    }

    @media (max-width: $ppui-grid-media-query-lg) {
      display: initial;
    }
  }

  @media (max-width: $ppui-grid-media-query-lg) {
    box-shadow: none;
  }
}
