@import '../../../mixins';
@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.speak-with-our-loan-forgiveness-specialist {
  @include cover-background;
  background-image: url('../../../shared/images/headset-talking.jpg');
  height: 32rem;
  transform: scaleX(-1);
  margin: auto;

  @media (max-width: $ppui-grid-media-query-sm) {
    height: 40rem;
  }

  &__content {
    transform: scaleX(-1) !important;
    text-align: left !important;
  }

  &__our-team{
    @media (min-width: $ppui-grid-media-query-md) {
      width: 60%;
    }
  }

  &__phone {
    font-size: inherit;
    color: inherit;

    &:hover {
      color: $ppui-color-white;
    }
  }
}
