@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.hero-faq-background {
  background-image: url("../../../shared/images/headset-talking.jpg");
  background-position: 50%;
}

.hero-faq-content {
  height: 16rem;

  @media (min-width: $ppui-grid-media-query-lg) {
    padding-top: 7rem;
    height: 32rem;
  }
}
