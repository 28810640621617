@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.ppbl-frequently-asked-questions {

  button {
    word-break: normal;
  }

  .ppvx_accordion__header {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    word-break: normal;

    &::after {
      top: 2rem;
    }
  }

  .accordion {
    text-align: left;
  }
}

.ppbl-frequently-asked-questions-button {
  background-color: $ppui-color-grey-100;

  a {
    &:hover {
      &::before {
        opacity: 0;
      }
    }
  }
}
