@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

$counter: slide-button-counter;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.how-the-application-process-works {
  text-align: left;

  .slide-button {
    color: $ppui-color-grey-700;
    background-color: initial;
    border: none;
    border-radius: 0;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 1rem;

    .number {
      color: $ppui-color-grey-700;
      flex: 0 0 auto;
      height: 3rem;
      width: 3rem;
      border: 1px solid $ppui-color-grey-300;
      border-radius: 100%;
      margin-top: auto;
      margin-bottom: auto;
      line-height: 2.7rem;
      text-align: center;
      margin-right: 1rem;
    }

    &.selected-slide-button {
      .number {
        background-color: $ppui-color-grey-300;
        border: none;
        color: $ppui-color-white;
      }

      .text {
        color: $ppui-color-grey-600;
        font-weight: 700;
      }
    }

    &:active {
      text-decoration: none;
    }
  }

  .slide-container {
    .slide-image {
      animation: fadeOut ease .5s;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-height: 100%;
      height: 400px;
      width: auto;
      max-width: 100%;

      &.selected-slide-image {
        animation: fadeIn ease .5s;
        opacity: 1;
      }
    }
  }
}
