@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.it-takes-just-minutes-to-check-eligibility {
  padding-top: 5.375rem;

  .check-eligibility-button {
    &:hover {
      background-color: transparent;
    }
  }

  h2 {
    font-size: 2.25rem;
  }

  h2, p {
    padding-bottom: 2.5rem;
  }

  .disclaimers {
    text-align: left;
    padding-bottom: inherit;
    max-width: 56.25rem;
    margin: auto;
    line-height: 1.4rem;

    &__sup {
      margin-top: 0.5rem;
    }
  }
}
