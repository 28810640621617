@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.hero-direct-mail-background {
  background-image: url("./rolling-pin.jpg");
  background-position: 50%;

  @media (min-width: $ppui-grid-media-query-md) {
    padding-bottom: 13rem;
  }

  @media (min-width: $ppui-grid-media-query-lg) {
    padding-bottom: 7.5rem;
  }
}

.hero-direct-mail-content {
  height: 19rem;
  text-align: left;

  @media (min-width: $ppui-grid-media-query-lg) {
    padding-top: 7rem;
    height: 32rem;
  }

  @media (max-width: $ppui-grid-media-query-md) {
    height: auto;
  }

}

.disclosure-content {
  max-width: 50rem;

  @media (min-width: $ppui-grid-media-query-lg) {
    padding-top: 3.5rem;
  }

  @media (max-width: $ppui-grid-media-query-md) {
    height: auto;
  }

  @media (min-width: $ppui-grid-media-query-sm) {
    margin-top: 3rem;
  }

}
