@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.ppp-loan-basics {
  background-color: $ppui-color-grey-100;
  padding-top: 2rem;
  padding-bottom: 3rem;

  &__header {
    text-align: center;
  }

  &__sba-link {
    text-align: center;
    display: block !important; // have to override the display from no-line-animation
    margin-bottom: 3rem;

    // larger font on Desktop
    @media (min-width: $ppui-grid-media-query-sm) {
      font-size: $ppui-font-size-xl;
    }
  }

  &__terms-and-conditions {
    text-align: center;

    @media (max-width: $ppui-grid-media-query-sm) {
      margin-top: 2rem;
    }
  }
}
