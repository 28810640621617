@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.why-a-loanbuilder-loan {
  .row-of-bullets {
    padding-bottom: 1.85rem;
  }

  .disclosure {
    &-item {  
      padding: .5rem 1.25rem;
      color: $ppui-color-grey-700;
      margin: auto;
      line-height: $ppui-line-height-sm;

      &__sup {
        font-size: $ppui-font-size-xs;
      }
    }
  }
}
