@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.sl-why-a-loanbuilder-loan {
  background-color: $ppui-color-grey-200;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &__bullet-text {
    text-align: center;
    justify-content: center;
    display: inline-grid;
  }

  &__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__disclaimer {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__header {
    text-align: center;
  }
}
