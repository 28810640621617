@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import "~@swift-paypal/pp-vx/scss/components/grid/grid.scss";
.footer {
  .right-column {
    text-align: left;
    @media (min-width: $ppui-grid-media-query-sm) {
      text-align: right;
    }

    .version {
      color: $ppui-color-grey-300;
    }

    p {
      line-height: 1.125rem;
      color: #2c2e2f;
      padding: 0.4375rem 0;
      margin: 0;
    }

    a {
      text-decoration: none;
      color: black;
      font-weight: inherit;
    }
  }

  .footer__logo {
    width: 15.625rem;
    height: 2.4375rem;
    margin-bottom: 2.25rem;
    display: inline-block;
  }

  .padding-bottom {
    padding-bottom: 2.5rem;
  }

  .footer-container {
    padding-top: 2.125rem;
    margin-bottom: 5.625rem;;
  }
}
