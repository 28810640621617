@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.ppbl-check-eligibility-faq {
  background-color: $ppui-color-grey-100;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .disclosures {
    margin: 3em 0 0;
    text-align: left;

    p {
      font-size: 0.8125rem;
    }
  }

  .sub-title {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
}
