@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.steps-for-forgiveness-title {
  text-align: center;
  margin-bottom: 1rem;

  .steps-for-forgiveness-header {
    color: $ppui-color-grey-700;
  }
}

.steps-for-forgiveness-content {
  margin: 0 0 1rem;
  justify-items: center;
  align-items: center;

  .step {
    display: inline-flex;
    margin: 0 1rem 0;

    .step-image {
      color: $ppui-color-grey-700;
      background-color: initial;
      border: none;
      border-radius: 0;
      display: flex;
      text-align: left;
      align-items: center;
      margin-bottom: 1rem;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        align-items: start;
      }

      .number {
        color: $ppui-color-grey-700;
        flex: 0 0 auto;
        height: 3rem;
        width: 3rem;
        border: 1px solid $ppui-color-grey-300;
        border-radius: 100%;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 2.7rem;
        text-align: center;
        margin-right: 1rem;
      }
    }
  }
}

.steps-for-forgiveness-disclaimer {
  text-align: center;
  padding-bottom: 2rem;

  .learn-more {
    width:20rem;
    margin-bottom: 6.25rem;

    @media (max-width: $ppui-grid-media-query-sm ) {
      width: 15rem;
      margin-bottom:1rem;
    }
  }

  p {
    text-align: left;
  }
}
