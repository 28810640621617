@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.ppp-loan-info-mobile-hero {
  text-align: center;

  &__btn {
    margin-left: .25rem;
    width: 20rem;
    
    @media (min-width: $ppui-grid-media-query-sm) {
      display: none !important;
    }
  }

  &__text-block {
    margin-top: 1.5rem;

    @media (min-width: $ppui-grid-media-query-sm) {
      display: none;
    }
  }

  &__get-started {
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media (min-width: $ppui-grid-media-query-sm) {
      display: none !important;
    }
  }
}
