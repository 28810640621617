@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import "../../../mixins";
@import "../../../variables";

.header {
  height: 3.4375rem;
  background-color: $ppui-color-white;
  box-shadow: 0 0.2rem 4rem 1rem $shadow;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: index($layers, head);

  @media (min-width: $ppui-grid-media-query-lg) {
    background-color: $ppui-color-transparent;
    box-shadow: none;
    height: 4.5625rem;
    position: static;
  }
}

.home-logo {
  display: none;

  @media (min-width: $ppui-grid-media-query-lg) {
    display: block;
    width: 16.5625rem;
    height: 4.1875rem;
    margin-left: 0.625rem;
  }
}

.blue-link {
  color: $pal-blue;

  a:hover {
    color: $pal-blue;
  }
}

.log-in {
  display: none;

  @media (min-width: $ppui-grid-media-query-lg) {
    display: block;
  }

  float: right;
  font-size: 0.9375rem;
  text-transform: none;

  .btn-white {
    border-radius: 1.5625rem;
    border: 0.0625rem solid white;
    background-color: transparent;
    padding: 0.5rem 1.4375rem;
    font-size: 0.75rem;
  }

  .btn-blue {
    border-radius: 1.5625rem;
    padding: 0.5rem 1.4375rem;
    font-size: 0.75rem;
  }

  li {
    display: inline-block;
    &:nth-child(2) {
      padding-left: 0.625rem;
    }
  }
}
