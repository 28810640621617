@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.mobile-sub-hero {
  display: none;

  .ppvx_btn {
    width: 100%;
    max-width: 15rem;
    min-width: 10rem;
    position: relative;
  }

  @media (max-width: $ppui-grid-media-query-lg) {
    display: initial;
  }

  .sub-header-text {
    margin: 1rem 0rem;
  }

  .button-section {
    margin-bottom: 2rem;

    &__text {
      margin-top: 2rem;
    }
  }
}
