@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.getting-ready-to-apply {
  padding: 2rem 0 2rem;
  color: $ppui-color-white;
  background-color: $ppui-color-blue-500;
  background-image: radial-gradient(circle farthest-side at center bottom, $ppui-color-blue-500,$ppui-color-blue-800 125%);

  .title-and-text {
    display: block;
    justify-content: center;
    text-align: center;
  }

  .needed-documentation {
    display: inline-flex;
    min-width: 20rem;
    justify-items: flex-start;
    align-items: center;
    margin: 2rem 1rem 2rem;

    @media (max-width: $ppui-grid-media-query-sm ) {
      min-width: 15rem;
      width: 15rem;
    }

    .image {
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
    }
  }
}
