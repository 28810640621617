@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.ppp-loan-start {
  text-align: center;

  &__button {
    margin-top: 2rem;
    @media (min-width: $ppui-grid-media-query-sm) {
      width: 20rem;
    }

    @media (max-width: $ppui-grid-media-query-sm) {
      width: 75%;
    }
  }

  &__text {
    padding: 0% 30%
  }

  &__footer {
    margin-top: 8rem;
  }
}
