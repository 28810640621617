@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.ppp-loan-info-bullet {
  margin-bottom: 3rem;
  padding: 0.6rem;

  &.left-align {
    text-align: left;
  }

  .icon {
    height: 4.1rem;
    width: 4.1rem;
    margin: 0 auto;

    @media (max-width: $ppui-grid-media-query-md) {
      margin-bottom: 0;
      width: 100%;
    }

    img {
      @extend .icon;
    }
  }

  @media (min-width: $ppui-grid-media-query-md) {
    margin-bottom: 0;
    .ppvx_row {
      justify-content: center !important;
      &.left-align {
        justify-content: left !important;
      }
    }
    .icon, .icon img {
      margin: 0 auto;
    }
  }

  .grey-text {
    color: $ppui-color-grey-600;
  }
  .white-text {
    color: $ppui-color-white;
  }
}
