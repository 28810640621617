// import using ~
@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "~@swift-paypal/pp-vx/dist/core/normalize.css";
@import "~@swift-paypal/pp-vx/dist/core/fonts.css";
@import "~@swift-paypal/pp-vx/dist/core/utilities/form-common.css";
@import "~@swift-paypal/pp-vx/dist/components/icons/icons.css";
@import "~@swift-paypal/pp-vx/dist/components/accordion/accordion.css";
@import "~@swift-paypal/pp-vx/dist/components/links/links.css";
@import "~@swift-paypal/pp-vx/dist/components/grid/grid.css";
@import "~@swift-paypal/pp-vx/dist/components/text/text.css";
@import "~@swift-paypal/pp-vx/dist/core/utilities/utilities.css";
@import "~@swift-paypal/pp-vx/dist/components/button/button.css";
@import "~@swift-paypal/pp-vx/dist/core/utilities/variables.css";
@import "./mixins";
@import "./variables";

.content-block {
  background-color: $ppui-color-white;
  color: $ppui-color-black;
  padding: 3.1rem 0;
  position: relative;
  text-align: center;
  z-index: index($layers, content-block);

  &.inverted {
    background-color: $ppui-color-grey-700;
    color: $ppui-color-white;

    .disclosure {
      color: $ppui-color-white;
    }
  }

  &.blue {
    background-color: $ppui-color-blue-600;
    background-image: radial-gradient(circle farthest-side at center bottom, $ppui-color-blue-500,$ppui-color-blue-800 125%);
  }

  .disclosure {
    color: $ppui-color-grey-500;
  }

  .quote {
    text-align: left;
    padding-top: 6rem;
    padding-bottom: 8rem;

    .small {
      display: block;
    }

    .medium {
      display: none;
    }

    @media (min-width: $ppui-grid-media-query-md) {
      .small {
        display: none;
      }

      .medium {
        display: block;
      }
    }

    @media (min-width: $ppui-grid-media-query-lg) {
      padding-top: 12rem;
      padding-bottom: 10rem;
    }
  }
}

.disclosure {
  text-align: left;
}

.row-of-bullets {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 3.7rem;
  max-width: 59rem;
  margin: 0 auto;

  @media (min-width: $ppui-grid-media-query-md) {
    align-items: stretch;
    flex-direction: row;
  }
}

a {
  text-decoration: none;
  font-size: inherit;
  position: relative;

  &::before {
    @include anchor-animation;
  }

  &:hover {
    text-decoration: none;

    &::before {
      opacity: 1;
      width: 100%;
    }
  }

  &.no-line-animation  {
    display: inline-block;

    &::before {
      background-color: transparent;
    }
  }

  &.underline {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      background-color: transparent;
    }
  }

  &.underline-when-hover {
    &:hover {
      text-decoration: underline !important;
    }

    &::before {
      background-color: transparent;
    }
  }
}

a.btn,
a.btn--white-border {
  &:hover {
    &::before {
      display: none;
    }
  }
}

.blue-link {
  color: $pal-blue;
}

.right {
  float: right;
}

.light-blue {
  color: $tertiary-bg;
}

.center {
  text-align: center;
}
