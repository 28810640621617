@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.who-can-use-3508s {
  margin-bottom: 2rem;

  &__header {
    text-align: center;
  }

  &__button {
    display: block !important; // Have to do this because of `no-line-animation`
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $ppui-grid-media-query-sm) {
      width: 20rem;
    }

    @media (max-width: $ppui-grid-media-query-sm) {
      width: 20rem;
    }
  }
}
