@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "../../../../variables";

.mobile-navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @media (min-width: $ppui-grid-media-query-lg) {
    display: none;
  }

  &.x {
    box-shadow: 0 0.5rem 4rem 1rem rgba(0, 0, 0, .17);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $ppui-color-white;
    width: 100%;

    @media (min-width: $ppui-grid-media-query-md) {
      width: 65%;
    }

    .nav-links {
      display: block;
    }

    .hamburger-wrap {
      .hamburger {
        background-color: $ppui-color-transparent;

        &::before {
          margin-left: -0.6rem;
          margin-top: 0.7rem;
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .home-logo-mobile {
    display: block;
    width: 16.5625rem;
    height: 4.1875rem;
    margin-top: -0.3125rem;
    margin-left: 0.625rem;

    @media (min-width: $ppui-grid-media-query-lg) {
      display: none;
    }
  }

  .hamburger-wrap {
    position: absolute;
    right: 0;
    left: 0;
    top: 0rem;
    height: 3.75rem;
    margin: 0 0 0 auto;
    width: 3.75rem;

    .hamburger {
      position: absolute;
      height: 0.1875rem;
      width: 2.1875rem;
      right: 0.625rem;
      background-color: $primary-header;
      margin: 2rem 0 0;
      top: -0.4375rem;
      content: "";
      transition: all 0.3s ease-in-out;

      &::before,
      &::after {
        display: block;
        content: "";
        position: fixed;
        height: 0.1875rem;
        width: 2.1875rem;
        background-color: $primary-header;
        transition: all 0.3s ease-in-out;
      }

      &::before {
        transform-origin: right;
        margin-top: -0.6875rem;
      }

      &::after {
        transform-origin: left;
        margin-top: 0.6875rem;
      }
    }
  }

  .nav-links {
    display: none;
    width: 100%;
    padding-left: 1rem;

    .nav-entry {
      text-align: left;

      a {
        padding-top: 1.25rem;
        padding-bottom: 0.3rem;
        color: $ppui-color-black;
        display: block;
        width: 100%;

        &::before {
          top: initial;
        }
      }

      .child-links {
        box-shadow: 0 0 0.9rem 0 rgba(0, 0, 0, 0.39);
        left: 0;
        position: initial;
        width: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
