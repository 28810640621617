@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.existing-ppp-loan {
  padding-top: 2rem;
  padding-bottom: 4rem;

  &__line-break {
    // hide on mobile
    @media (max-width: $ppui-grid-media-query-sm) {
      display: none;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__link {
    font-size: inherit;
  }

  &__phone {
    font-size: inherit;
    font-weight: inherit;
  }
}
