$primary-cta: #f47e1f;                  // orange
$primary-text: #505050;                 // dark grey
$primary-header: #3d3d3d;               // darker grey
$primary-table-highlight: #ebebeb;      // lightish grey
$secondary-table-highlight: #024a85;    // mixed blue
$modal-bg: #023763;                     // dark blue
$loader-bg: rgba(255, 255, 255, 0.9);
$loan-payoff-border: #c0c0c0;
$shadow:  rgba(0, 0, 0, .17);

//PayPal Colors
$pay-blue: #003087;
$pal-blue: #009cde;

/**
 * Used to manage z-index behavior. When applying a z-index value to any element in this project,
 * use this convention:
 *
 *     z-index: index($layers, <layer-name>);
 *
 * This will keep element order easy to manage and makes inserting new layers without having to
 * correct other elements trivial.
 *
 * For maintainability purposes, avoid giving layers generic names like "background" or
 * "foreground". Instead, name the layer after the specific item or group of items that will use
 * it, such as "hero" or "mailer".
 */
$layers: default mailer content-block call-us-today head;
$tertiary-bg: #1baff8;

$inner-width: 1180px;
