@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.row-bullet {
  margin-bottom: 3rem;
  padding: 0.6rem;
  width: 14.2rem;

  @media (min-width: $ppui-grid-media-query-md) {
    margin-bottom: 0rem;
  }

  &__icon {
    display: block;
    margin: auto;
    height: 4.1rem;
    width: 4.1rem;

    .blue {
      filter: invert(47%) sepia(87%) saturate(2595%) hue-rotate(168deg) brightness(90%) contrast(101%);
    }
  }

  &__text {
    font-size: $ppui-font-size-md;
    text-align: center
  }

  &__title {
    font-size: $ppui-font-size-md;
    text-align: center;
  }
}
