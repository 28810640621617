.secondary-navigation {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;

  li {
    padding-bottom: 0.5rem;
    .link-text {
      font-family: PayPalSansBig-Regular;
      color: #3d3d3d;
      line-height: 1.3rem;
      font-weight: 400;
      text-decoration: none;
    }
  }
}
