@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import '../../../variables';

.site-link-more-info-page {
  @media (max-width: $ppui-grid-media-query-lg) {
    .ppvx_text--xl4 {
      font-size: 1.875rem;
      line-height: 2.25rem;
      font-weight: 400;
    }

    .ppvx_text--xl3 {
      font-size: 1.25rem;
      line-height: 1.875rem;
      font-weight: 400;
    }
  }
}