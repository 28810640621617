@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";

.sba-link{
  &:hover {
    text-decoration: solid;
  }

  .disclaimer {
    color: $ppui-color-grey-600;
    text-decoration: none;
  }
}
