@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.check-eligibility-button {
  min-width: 17.5rem;

  &:hover {
    background-color: $ppui-color-blue-700;
    border-color: $ppui-color-blue-700; 
  }

  &:focus {
    text-decoration: none;
  }
}
