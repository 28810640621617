@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import '../../variables';

.ppbl-faq-page {
  a.ppvx_link {
    &:hover {
      text-decoration: underline;
      &::before {
        opacity: 0;
      }
    }
  }

  .hero {
    @media (max-width: $ppui-grid-media-query-lg) {
      height: 300px;
      margin-top: 0;
    }
  }

  @media (max-width: $ppui-grid-media-query-lg) {
    .ppvx_text--xl4 {
      font-size: 1.875rem;
      line-height: 2.25rem;
      font-weight: 400;
    }

    .ppvx_text--xl3 {
      font-size: 1.25rem;
      line-height: 1.875rem;
      font-weight: 400;
    }
  }
}
