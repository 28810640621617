@import "../../../../../mixins";

.nav-entry  {
  text-align: center;
  position: relative;
  margin-right: 2.5rem;
  text-decoration: none;
}

.sub-menu {
  position: relative;
  z-index: 1;
  border: none;
  outline: none;
  text-align: center;
  margin: 0 3rem;
  text-decoration: none;
}

.child-links-body {
  z-index: 1;
  overflow: hidden;
  background-color: white;
  position: absolute;
  max-height: 10rem;
  width: 10rem;

  a {
    color: #505050 !important;
    text-align: left;
    font-weight: 200;
    display: block;
    line-height: 1.25rem;
    padding-top: 0.625rem;

    &::before {
      top: initial;
    }
  }
}

.child-links {
  padding: 0;
  list-style-type: none;
}

.child-link-item {
  padding: 0.25rem 0;
}

.list-transition-enter {
  max-height: 0;
}

.list-transition-enter-active {
  max-height: 10rem;
  transition: all 0.3s;
}

.list-transition-done {
  max-height: 10rem;
}

.list-transition-exit {
  max-height: 10rem;
}

.list-transition-exit-active {
  max-height: 0;
  transition: all 0.3s;
}

.dropdown-arrow {
  width: 0.9375rem;
  height: 0.9375rem;
  position: relative;
  top: 0.125rem;
  bottom: 0.125rem;
}
