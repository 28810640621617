@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.apply-now-button{
  min-width: 20rem;
  display: inline-block;

  &::before {
    background-color: transparent;
  }

  @media (max-width: $ppui-grid-media-query-sm ) {
    display: none;
  }
}

.mobile-apply-now-button{
  width: 15rem;
  margin: 1rem auto;
  display: block;

  &::before {
    background-color: transparent;
  }

  @media (min-width: $ppui-grid-media-query-sm) {
    display: none;
  }
}
