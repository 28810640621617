@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.hero-content {
  text-align: center;
  padding: 2rem 0 2rem;

  @media (min-width: $ppui-grid-media-query-lg) {
    padding-top: 3.125rem;
  }

  .icon {
    max-width: 4.125rem;
    max-height: 4.125rem;
  }

  .disclosure {
    align-items: flex-end;
    flex-grow: 1;
    margin-top: 6rem;
    text-align: center;
  }
}

.hero-background {
  background-color: $ppui-color-blue-600;
}
