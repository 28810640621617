@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
.ppp2-hero-background {
  background-image: url('../../pppShared/heroContent/ppp-default-hero-background.jpg');
  background-position: center;
  background-size: cover;
  margin-top: 0;
  padding-bottom: 2rem;

  @media (max-width: $ppui-grid-media-query-sm) {
    height: 20rem;
  }
}

.ppp2-hero {
  &__title {
    margin-bottom: 0;

    @media (min-width: $ppui-grid-media-query-md) {
      margin-left: 2rem;
      margin-right: 2rem;
      text-align: left;
    }

    @media (max-width: $ppui-grid-media-query-sm) {
      text-align: center;
    }
  }

  &__text-block {
    margin-top: 1rem;
    width: 60%;

    @media (min-width: $ppui-grid-media-query-md) {
      margin-left: 2rem;
      margin-right: 2rem;
      text-align: left;
    }

    @media (max-width: $ppui-grid-media-query-sm) {
      display: none;
    }
  }
}

.ppp2-mobile-hero {
  @media (min-width: $ppui-grid-media-query-sm) {
    display: none;
  }

  &__text-block {
    margin-top: 1rem;

    @media (max-width: $ppui-grid-media-query-sm) {
      margin-left: 2rem;
      margin-right: 2rem;
      text-align: center;
    }
  }
}
