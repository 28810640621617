@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.sitelink-more-info-hero {
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 26rem;

  &__content {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media (min-width: $ppui-grid-media-query-sm) {
      max-width: $ppui-grid-media-query-lg / 2;
    }
  }

  &__caption {
    font-size: $ppui-font-size-sm;
    margin-top: 2rem;

    @media (min-width: $ppui-grid-media-query-sm) {
      max-width: $ppui-grid-media-query-lg * 2/3;
    }
  }
}
