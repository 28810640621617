@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.digital-page {
  .hero {
    background-position: 24% 0%;
    min-height: 37.8125rem;

    @media (min-width: $ppui-grid-media-query-lg) {
      min-height: 31.25rem;
    }
  }
}
