@import '~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss';

.funding-unavailable-button {
    min-width: 17.5rem;
    margin-top: 20px;
    &:hover {
      background-color: $ppui-color-blue-700;
      border-color: $ppui-color-blue-700; 
    }
  
    &:focus {
      text-decoration: none;
    }
  }
  