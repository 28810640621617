@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "~@swift-paypal/pp-vx/scss/components/text/text.scss";
@import "../../../variables";

.things-you-should-know {

  @media (max-width: $ppui-grid-media-query-lg) {
    display: none;
  }

  padding: 2rem 0 2rem;
  color: $ppui-color-white;
  background-color: $ppui-color-blue-500;
  background-image: radial-gradient(circle farthest-side at center bottom, $ppui-color-blue-500,$ppui-color-blue-800 125%);
}

.things-you-should-know-mobile-view {

  @media (min-width: $ppui-grid-media-query-lg) {
    display: none;
  }

  padding: 2rem 0 0.125rem;
  color: $ppui-color-white;
  background-color: $ppui-color-blue-500;
  background-image: radial-gradient(circle farthest-side at center bottom, $ppui-color-blue-500,$ppui-color-blue-800 125%);

  .accordian-override {
    .ppvx_accordion {
      border-collapse: collapse;
    }

    .ppvx_accordion__row {
      border-bottom: $ppui-border-width-xs solid;
      margin-bottom: 0.5rem;
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .ppvx_accordion__row:last-of-type {
      border-bottom: none;
      margin-bottom: 0.5rem;
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .ppvx_accordion__row:first-of-type {
      border-top: $ppui-border-width-xs solid;
      padding-top: 0.5rem;
    }
    .ppvx_accordion__body {
      display: none;
      padding: 0.5rem 1.25rem 0;
      color: $ppui-color-white;
      font-size: 1rem;
      line-height: 1.5rem;
      font-family: "PayPalSansSmall-Regular", Helvetica Neue, Arial, sans-serif;
      font-weight: normal;

      p {
        margin: 0 0 1.2rem 0;
        text-align: left;
      }

      ul {
        padding-left: 0;
        text-align: left;

        li {
          list-style-type: none;
        }
      }
    }

    .ppvx_accordion__header {
      cursor: pointer;
      padding: 0rem 2.5rem 0.25rem 1rem;
      transition: 0.2s ease-in-out;
      position: relative;
      color: $ppui-color-white;
      border: none;
      width: 100%;
      text-align: left;
      font-size: 1rem;
      line-height: 1.3rem;
      font-family: "PayPalSansBig", sans-serif;
      font-weight: 100;
      word-break: break-word;

      &.first-header {
        padding-left: 1.25rem;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 1rem;
        bottom: 10px;
        width: 1px;
        height: 1.25rem;
        background-color: $ppui-color-white;
        transition: 0.2s ease-in-out;
      }

      &::before {
        transform: rotate(45deg);
        right: 1.5rem;
      }

      &::after {
        transform: rotate(-45deg);
        right: 2.35rem;
      }
    }

    .ppvx_accordion__header:focus {
      outline: none;
    }

    .ppvx_accordion__header[aria-expanded='true'] {
      color: $tertiary-bg;
      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
    .ppvx_accordion__header[aria-expanded='true'] + .ppvx_accordion__body {
      display: block;
    }
  }
}