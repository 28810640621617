@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.home-page {
  .hero {
    min-height: 37.8125rem;

    @media (min-width: $ppui-grid-media-query-lg) {
      min-height: 31.25rem;
    }
  }
}
