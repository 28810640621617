@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "../checkEligibilityButton/checkEligibilityButton.scss";

.funding-code {
  &__field-container {
    display: flex;
    padding-top: 0.875rem;
    vertical-align: top;
    width: 100%;

    .dash--offer {
      display: block;
      text-align: center;
      font-size: 2rem;
      margin: 0.25em 1rem -1.75rem;
    }

    .first-field {
      width: 5.2rem;
    }

    .second-field {
      width: 7.9rem;
    }
  }

  &__submit-button {
    padding-top: 1rem;

    @media (min-width: $ppui-grid-media-query-lg) {
      margin-left: -3rem;
    }

    @media (max-width: $ppui-grid-media-query-md) {
      justify-content: center;
      text-align: center;
      margin-top: 1rem;
    }

    @media (max-width: $ppui-grid-media-query-sm) {
      margin-bottom: 1rem;
    }

    Button:focus, Button:active {
      background-color: $ppui-color-blue-600;
    }
  }
}
