.download-a-loan-calculator {
  padding: 3rem 0 3rem;
  .download-a-loan-calculator-title {
    margin: 0;
    text-align: center;
  }

  .download-a-loan-calculator-content {
    text-align: center;
    padding: 1rem 0 1rem;
  }

  .download-a-loan-calculator-button {
    align-items: center;
    justify-items: center;
    text-align: center;
    a {
      min-width: 20rem;
    }
  }
}
