@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.ppbl-check-eligibility-dm {
    background: $ppui-color-grey-100;

    .details {
        font-size: $ppui-font-size-md;
        margin: 2rem 0;
    }

    .disclosures {
        font-size: $ppui-font-size-sm;
        text-align: left;
    }
}