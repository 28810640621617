@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";
@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import "../../mixins";
@import "../../variables";

.terms-and-conditions-page {


  h2 {
    font-family: PayPalSansBig-Light, sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3rem;
  }

  .header--sub {
    font-family: PayPalSansBig-Light, sans-serif;
    font-size: 18px;
    line-height: 1.3;
    padding-top: 10px;
  }

  .grid__bg--dark {
    text-align: center;
  }

  .section--legal-documents {
    line-height: 1.85em;
    font-size: 15px;
  }
}
