@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.hero-ppp-loan-background {
  background-image: url("ppp-default-hero-background.jpg");
  background-position: center;
  background-size: cover;
}

.hero-ppp-loan-content {
  padding: 2rem 0 2rem;
  min-height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $ppui-grid-media-query-sm) {
    display: block;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
}

.hero-title {
  margin-bottom: 0;
  @media (max-width: $ppui-grid-media-query-sm) {
    text-align: center;
  }
}

.hero-sub-title {
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: $ppui-grid-media-query-sm) {
    text-align: center;
  }
}

.hero-content-text {
  margin-left: 2rem;
  margin-top: 1.34rem;
  @media (max-width: $ppui-grid-media-query-sm) {
    margin-left: inherit;
    display: none;
  }
}

.hero-apply-now {
  margin-left: 2rem;
  width: 100%;
  @media (max-width: $ppui-grid-media-query-sm) {
    margin-left: 0;
  }
}

.mobile-hero-text {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  align-self: center;

  @media (min-width: $ppui-grid-media-query-sm) {
    display: none;
  }
}
