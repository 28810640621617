@import "~@swift-paypal/pp-vx/scss/core/utilities/variables.scss";
@import "../../../../mixins";

.speak-with-our-business-funding-experts {
  @include cover-background();

  background-image: url('./customer-focus_image.jpg');
  text-align: left;

  .ppvx_text--xl2 {
    .ppvx_link {
      color: $ppui-color-white;
      font-size: 1.5rem;
    }
  }


  @media (max-width: $ppui-grid-media-query-lg) {
    text-align: center;
  }
}
